<template>
	<div class="main-wrap h100">
		<div class="flex w100">
			<div><MainMenu :list="menus"/></div>
	
			<div id="Page-Judges-List" class="w850" v-if="$route.params.id">
				<div class="ta-c mt-20" v-if="loading">
					<img class="loading" src="@/assets/common/loading_d.gif" />
				</div>
				
				<template v-if="list1 && list1.length>0">
					<div class="title-h2">ACADEMIC CONSULTANT</div>
					<div class="title-h1">{{L("学术顾问")}}</div>
					<!-- <div class="mt-10"></div> -->
					<div class="flex between wrap list">
						<div v-for="item in list1" :key="item.id" class="item" @click="activeId!=item.id?(activeId = item.id):(activeId = '')">
							<div class="coverBox">
								<CoverWrap :src="item.cover+'?x-oss-process=image/resize,w_460'" width="190px" height="190px"/>
							</div>
							<div class="title-box" :class="{active:activeId==item.id}">
								<div class="etitle">{{item.ename}}</div>
								<div class="title">{{L(item.name)}}</div>
							</div>
							<div class="more-box" :class="{animation:activeId==item.id,active:activeId==item.id}">
								<div class="city _en">{{item.ecity}}</div>
								<div class="city _cn">{{L(item.city)}}</div>
								<div class="">－</div>
								<div class="description _cn" v-html="L(item.description)"></div>
								<div class="description _en" v-html="item.edescription"></div>
							</div>
						</div>
						
						<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
					</div>
					<!-- <div style="margin-top:30px"></div> -->
				</template>
				<div style="margin-top:15px"></div>
				<template v-if="list2 && list2.length>0">
					<div class="title-h2">EDITORIAL COMMITTEE</div>
					<div class="title-h1">{{L("特邀编委")}}</div>
					<!-- <div class="mt-20"></div> -->
					<div class="flex between wrap list">
						<div v-for="item in list2" :key="item.id" class="item" @click="activeId!=item.id?(activeId = item.id):(activeId = '')">
							<div class="coverBox">
								<CoverWrap :src="item.cover+'?x-oss-process=image/resize,w_460'" width="190px" height="190px"/>
							</div>
							<div class="title-box" :class="{active:activeId==item.id}">
								<div class="etitle">{{item.ename}}</div>
								<div class="title">{{L(item.name)}}</div>
							</div>
							<div class="more-box" :class="{animation:activeId==item.id,active:activeId==item.id}">
								<div class="city _en">{{item.ecity}}</div>
								<div class="city _cn">{{L(item.city)}}</div>
								<div class="">－</div>
								<div class="description _cn" v-html="L(item.description)"></div>
								<div class="description _en" v-html="item.edescription"></div>
							</div>
						</div>
						<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
					</div>
				</template>
				
				
				
			</div>
		</div>
	</div>
</template>

<script>
import MainMenu from '/src/views/MainMenu';
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Page-Judges-List',
  components: {
    MainMenu,
		CoverWrap
  },
  data(){
    return {
			menus:this.$root.awardStageList.filter(item=>item.status_judge==1||item.status_judge==2).map(item=>{return {
				title:item.name,
				path:item.status_judge==2?'':'/Judges/List/'+item.id
			}}),
			id:this.$route.params.id,
			list1:[],
			list2:[],
			activeId:"",
			loading:false
    };
  },
	watch:{
		$route(){
			this.initJudge()
		}
	},
  async mounted () {
		this.initJudge()
		// var result = await this.request.zd.get('NEWS');
		// if(result && result.list) {
		// 	this.list = result.list;
		// } else {
		// 	this.list = [];
		// }
  },
  methods: {
		async initJudge(){
			this.loading = true;
			this.list1 = [];
			this.list2 = [];
			if(!this.$route.params.id){
				this.$router.replace(this.menus[0].path)
				this.loading = false;
				// this.$root.toPage(this.menus[0].path);
				return ;
			}
			
			var result = await this.request.zd.find('JUDGES_'+this.$route.params.id+'_');
			
			if(result && result.list){
				result.list = result.list.filter(item => item.awardStage == this.$route.params.id && item.status==1);
				var activeId = "";
				result.list.forEach(item => {
					if(item.description){
						item.description = item.description.replace(/\n/g,"<br/>");
						item.edescription = item.edescription.replace(/\n/g,"<br/>");
					}
				});
				this.list1 = result.list.filter(item => item.type == 1);
				this.list2 = result.list.filter(item => item.type == 2);
			}
			this.loading = false;
			
			// var result = await this.request.zd.get('JUDGES');
			// if(result && result.list){
			// 	result.list = result.list.filter(item => item.awardStage == this.$route.params.id && item.status==1);
			// 	var activeId = "";
			// 	result.list.forEach(item => {
			// 		if(item.description){
			// 			item.description = item.description.replace(/\n/g,"<br/>");
			// 			item.edescription = item.edescription.replace(/\n/g,"<br/>");
			// 		}
			// 	});
			// 	this.list1 = result.list.filter(item => item.type == 1);
			// 	this.list2 = result.list.filter(item => item.type == 2);
			// }
			// this.loading = false;
			
		}
  }
}
</script>

<style scoped lang="less">
#Page-Judges-List{
	padding-bottom:30px;
	.animation{
		-moz-transition:all .3s linear;
		-o-transition:all .3s linear;
		-webkit-transition:all .3s linear;
		transition:all .3s linear}
}
.title-h1,.title-h2{padding-left:10px;}
.list{
	.item{
		cursor: pointer;
		margin-bottom:30px;
		.coverBox{
			padding:0 10px;
		}
		.title-box{
			position: relative;
			padding: 15px 0;
			text-align: center;
			.etitle,.title{font-size:18px;line-height: 1.4;word-break:break-all; word-wrap: break-word;}
			&::after{
				content:"";
				display: block;
				position: absolute;
				right:10px;
				bottom:22px;
				width:17px;
				height:17px;
				background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4wMDgiIGhlaWdodD0iMTguMDA4IiB2aWV3Qm94PSIwIDAgMTguMDA4IDE4LjAwOCI+CiAgPGcgaWQ9Iue7hF8yNDQiIGRhdGEtbmFtZT0i57uEIDI0NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMDIuNzIxIC02MTYuOTIxKSI+CiAgICA8Y2lyY2xlIGlkPSLmpK3lnIZfMTI2IiBkYXRhLW5hbWU9IuakreWchiAxMjYiIGN4PSI4LjUwNCIgY3k9IjguNTA0IiByPSI4LjUwNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIwMy4yMjEgNjE3LjQyMSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEiLz4KICAgIDxnIGlkPSLnu4RfMjQzIiBkYXRhLW5hbWU9Iue7hCAyNDMiPgogICAgICA8cGF0aCBpZD0i6Lev5b6EXzYzMCIgZGF0YS1uYW1lPSLot6/lvoQgNjMwIiBkPSJNMTIxMi40MDksNjI4LjU5MnYtLjAwNmwtLjY4NC42ODUtLjY4NS0uNjg1di4wMDZsLTEuOTA2LTEuOTA3LTEuMDQyLDEuMDQyLDMuNjMzLDMuNjM0LDMuNjMzLTMuNjM0LTEuMDQyLTEuMDQyWiIvPgogICAgICA8cGF0aCBpZD0i6Lev5b6EXzYzMSIgZGF0YS1uYW1lPSLot6/lvoQgNjMxIiBkPSJNMTIxMi40MDksNjIzLjUyOXYtLjAwNmwtLjY4NC42ODUtLjY4NS0uNjg1di4wMDZsLTEuOTA2LTEuOTA3LTEuMDQyLDEuMDQyLDMuNjMzLDMuNjM0LDMuNjMzLTMuNjM0LTEuMDQyLTEuMDQyWiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==) no-repeat center center / 100% 100%;
			}
			&.active{
				&::after{
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC4wMDgiIGhlaWdodD0iMTguMDA4IiB2aWV3Qm94PSIwIDAgMTguMDA4IDE4LjAwOCI+CiAgPGcgaWQ9Iue7hF8yNzgiIGRhdGEtbmFtZT0i57uEIDI3OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0OTAuNjI2IC02MTYuOTIxKSI+CiAgICA8Y2lyY2xlIGlkPSLmpK3lnIZfMTM2IiBkYXRhLW5hbWU9IuakreWchiAxMzYiIGN4PSI4LjUwNCIgY3k9IjguNTA0IiByPSI4LjUwNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQ5MS4xMjYgNjE3LjQyMSkiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEiLz4KICAgIDxnIGlkPSLnu4RfMjc3IiBkYXRhLW5hbWU9Iue7hCAyNzciPgogICAgICA8cGF0aCBpZD0i6Lev5b6EXzY1MiIgZGF0YS1uYW1lPSLot6/lvoQgNjUyIiBkPSJNMTUwMC4zMTQsNjI4LjU5MnYtLjAwNmwtLjY4NC42ODUtLjY4NS0uNjg1di4wMDZsLTEuOTA2LTEuOTA3TDE0OTYsNjI3LjcyN2wzLjYzMywzLjYzNCwzLjYzMy0zLjYzNC0xLjA0Mi0xLjA0MloiIGZpbGw9IiNmZmYiLz4KICAgICAgPHBhdGggaWQ9Iui3r+W+hF82NTMiIGRhdGEtbmFtZT0i6Lev5b6EIDY1MyIgZD0iTTE1MDAuMzE0LDYyMy41Mjl2LS4wMDZsLS42ODQuNjg1LS42ODUtLjY4NXYuMDA2bC0xLjkwNi0xLjkwN0wxNDk2LDYyMi42NjRsMy42MzMsMy42MzQsMy42MzMtMy42MzQtMS4wNDItMS4wNDJaIiBmaWxsPSIjZmZmIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K);
				}
				
			}
		}
		
		.date{color:#A0A0A0;font-size:22px;}
		.line{height:1px;background-color: #A0A0A0;}
		&.empty {
			height: 0px;
			margin-bottom:0;
			width:210px;
			border:1px solid transparent;
		}
		.more-box{
			width:210px;
			font-size:12px;
			max-height: 0px;
			overflow: hidden;
			text-align: center;
			&.active{
				max-height: 800px;
			}
			.description{
				line-height: 1.5;
			}
			
			
		}
	}
}
</style>